import React from 'react';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';
import Dots from './Dots';
import ScholarshipItem from './ScholarshipItem';

export const ScholarshipsContent = ({
  blurbs,
  requirements,
  steps,
  deadline,
  details,
  flyers,
  html
}) => {
  return (
    <Section>
      <div className="space-y-8">
        {blurbs.map((item, index) => (
          <p
            key={index}
            className="px-0 text-2xl font-semibold text-left font-baloo text-s4tk-blue sm:text-justify md:px-4 lg:px-8"
          >
            {item.text}
          </p>
        ))}
      </div>
      <SubSection>
        <p className="self-center mb-4 text-3xl font-semibold text-left font-baloo sm:self-start text-s4tk-blue sm:text-justify">
          {requirements.title}
        </p>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </SubSection>
      <SubSection>
        <div className="flex flex-col items-center justify-around w-full lg:flex-row">
          <SmallLink item={steps[0]} />
          <Dots />
          <SmallLink internal item={steps[1]} />
          <Dots />
          <SmallLink internal item={steps[2]} />
        </div>
      </SubSection>
      <SubSection>
        <p className="self-center mb-2 text-3xl font-semibold text-left font-baloo text-s4tk-blue sm:text-justify">
          {deadline}
        </p>
      </SubSection>
      <SubSection>
        <p className="mb-4 text-2xl text-left text-black font-baloo sm:text-justify">
          {details.header}
        </p>
        <ol className="px-0 mb-4 space-y-2 list-decimal list-inside md:px-4 lg:px-8">
          {details.scholarships.map((item, index) => (
            <li
              key={index}
              className="text-xl text-left text-black font-baloo sm:text-justify"
            >
              {item.text}
            </li>
          ))}
        </ol>
        <p className="mb-4 text-2xl text-left text-black font-baloo sm:text-justify">
          {details.blurb}
        </p>
      </SubSection>
      <SubSection>
        <div className="flex flex-row flex-wrap items-center justify-around w-full">
          {flyers.map((item, index) => (
            <div
              key={index}
              id={item.id}
              className="flex flex-col items-center w-full pt-36"
            >
              <ScholarshipItem image={item.image} />
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                className="m-4 text-3xl font-bold text-left text-s4tk-blue font-baloo sm:text-justify"
              >
                Apply here
              </a>
            </div>
          ))}
        </div>
      </SubSection>
    </Section>
  );
};

export default ScholarshipsContent;
