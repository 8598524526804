import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import ScholarshipsContent from '../components/Scholarships/ScholarshipsContent';

export const ScholarshipsPageTemplate = ({
  title,
  blurbs,
  requirements,
  steps,
  deadline,
  details,
  flyers,
  html
}) => {
  return (
    <div className="flex flex-col w-full bg-s4tk-white">
      <Header title={title} />
      <ScholarshipsContent
        blurbs={blurbs}
        requirements={requirements}
        steps={steps}
        deadline={deadline}
        details={details}
        flyers={flyers}
        html={html}
      />
    </div>
  );
};

const ScholarshipsPage = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <ScholarshipsPageTemplate
        title={frontmatter.title}
        blurbs={frontmatter.blurbs}
        requirements={frontmatter.requirements}
        steps={frontmatter.steps}
        deadline={frontmatter.deadline}
        details={frontmatter.details}
        flyers={frontmatter.flyers}
        html={html}
      />
    </Layout>
  );
};

export default ScholarshipsPage;

export const scholarshipsPageQuery = graphql`
  query ScholarshipsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "scholarships-page" } }) {
      html
      frontmatter {
        title
        blurbs {
          text
        }
        requirements {
          title
          items {
            text
          }
        }
        steps {
          title
          icon {
            prefix
            title
          }
          url
        }
        deadline
        details {
          header
          scholarships {
            text
          }
          blurb
        }
        flyers {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          id
          url
        }
      }
    }
  }
`;
