import React from 'react';

export const Dots = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center space-y-4 space-x-0 lg:space-x-4 lg:space-y-0 p-4">
      <div className="w-3 h-3 rounded-full bg-gradient-to-b lg:bg-gradient-to-r from-transparent to-blue-200" />
      <div className="w-3 h-3 rounded-full bg-blue-300" />
      <div className="w-3 h-3 rounded-full bg-blue-400" />
      <div className="w-3 h-3 rounded-full bg-blue-500" />
      <div className="w-3 h-3 rounded-full bg-s4tk-blue" />
    </div>
  );
};

export default Dots;
