import React from 'react';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';

export const ScholarshipItem = ({ image }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full rounded-md shadow-lg select-none md:w-120">
      <PreviewCompatibleImage
        className="w-full h-full"
        imgStyle={{ objectFit: 'contain' }}
        image={image}
        alt="scholarship"
      />
    </div>
  );
};

export default ScholarshipItem;
